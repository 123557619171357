<script setup>
import settingshSVG from "@/assets/svg/settings-3-line.svg";

const props = defineProps({
  CONNECTED: { type: Boolean, required: true },
  JOINED: { type: Boolean, required: true },
  TEAM: { type: String, required: true },
});
const emit = defineEmits(["open-settings"]);
</script>

<template>
  <footer class="status-bar level mb-0">
    <div class="level-left">
      <div class="level-item"><p>You are playing Life Goes On.</p></div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <div>
          <a
            class="has-text-light pr-1"
            href="https://www.machinaex.com/de/kontakt"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressum & Datenschutz
          </a>
        </div>
        <div class="status-game-info">
          <p>
            Game ID:
            <strong :class="[props.JOINED ? 'has-text-white' : 'has-text-warning']">
              {{ props.JOINED ? TEAM : "-" }}
            </strong>
          </p>
          <p class="pl-2">
            Status:
            <strong
              class="is-uppercase"
              :class="[props.CONNECTED ? 'has-text-success' : 'has-text-danger']"
            >
              {{ props.CONNECTED ? `connected` : "no connection" }}
            </strong>
          </p>
        </div>
        <p class="pl-2">
          <a @click="emit('open-settings')">
            <img :src="settingshSVG" alt="Einstellungen" />
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>
